<template>
    <div class="rentingWrap">
        <div class="renting">
            <img src="../../assets/image/renting.png" alt="">
        </div>
    </div>
</template>

<script>

export default {
    name:"renting",
    data() {  
        return {

        };
    },
    methods: {},
    }
</script>
<style lang='scss' scoped>
.rentingWrap{
    height: 100vh;
    position: absolute;
    z-index: 1000;
    overflow-x: hidden;
    background: #f5f5f5;
    .renting{
        margin: 20px auto;
        img{
            display: block;
            width: 100%;
        }
    }
}
</style>